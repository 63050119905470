// BannersSlider helper functions and data
import { Storage } from "@capacitor/storage";
import { sanitizeText } from "@sushicorp/utils";
import { Banner } from "artisn/types";
import router from "next/router";

import CONSTANTS from "config/constants";

const { STORAGE } = CONSTANTS;
const { CATEGORY_TOKEN } = STORAGE;

/**
 * Helper to get banner url formatting.
 *
 * @param {Banner} banner Banner data.
 * @param {React.Dispatch<React.SetStateAction<string>>} setOpenProductModal Open modal with product id.
 */
export const goToBannerUrlHelper = async (
  banner: Banner,
  setOpenProductModal: React.Dispatch<React.SetStateAction<string>>
) => {
  const { category_id: categoryId, category_name: categoryName } = banner;
  const { product_id: productId } = banner;
  const { banner_type: type, banner_url: bannerUrl } = banner;
  if (bannerUrl) {
    window.open(bannerUrl, "_blank");
    return;
  }
  if (type === "CATEGORY" && categoryName) {
    try {
      await Storage.set({
        key: CATEGORY_TOKEN,
        value: JSON.stringify(categoryId)
      });
    } catch (e) {
      console.error("Cannot save category in storage");
    }
    const sanitizedName = sanitizeText(categoryName);
    router.push(`/categories/${categoryId}/${sanitizedName}`);
    return;
  }
  if (type === "PRODUCT") {
    setOpenProductModal(`${productId}`);
    return;
  }

  router.push(`/categories`);
};
