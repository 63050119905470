import styled from "styled-components";

import { HomeCardsStyledProps as Props } from "./HomeCards.types";
import CONSTANTS from "config/constants";
const { BREAKPOINTS } = CONSTANTS;

const { tablet, mobile } = BREAKPOINTS;

const HomeCardsStyled = styled.div<Props>`
  display: flex;
  margin-top: 8rem;
  margin-bottom: 6.4rem;
  gap: 0 1.6rem;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
    margin-top: 2.4rem;
    margin-bottom: 4rem;
  }

  .HomeCards {
    &__card {
      min-height: 32rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding-bottom: 0;
      border-radius: 2rem;
      overflow: hidden;
      background-image: url("/assets/images/banner-1.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
      }

      :last-child {
        background-color: var(--palette-pink-h28-s68-l72-a10);
        background-image: url("/assets/images/banner-2.jpg");
        margin-left: 2.4rem;

        @media (max-width: ${tablet}px) {
          margin-left: 0;
          margin-top: 1.6rem;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      padding: 1.6rem;
      padding-top: 3.6rem;
    }

    &__title {
      max-width: 33.6rem;
      text-align: center;
      border-radius: 3.2rem;
      font-size: 4.4rem;
      font-weight: 900;
      background-color: var(--palette-white);
      color: var(--palette-gray-s0-l20-a10);
      transform: rotate(-10deg);
      text-transform: uppercase;

      &--primary {
        color: var(--palette-yellow-h38-s92-l59-a10);
      }

      &--secondary {
        color: var(--palette-pink-h28-s68-l72-a10);
      }

      @media (max-width: ${tablet}px) {
        font-size: 4rem;
      }

      @media (max-width: ${mobile}px) {
        max-width: 20.6rem;
        font-size: 2.4rem;
      }
    }

    &__download-app-button {
      padding: 1.2rem 2rem;
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 800;
      border-radius: 2.4rem;
      width: 20rem;
      align-self: flex-end;
      background-color: var(--palette-primary);
      color: var(--palette-white);

      &--secondary {
        background-color: var(--palette-white);
        color: var(--palette-primary);

        &:hover {
          background-color: var(--palette-white) !important;
        }
      }
    }
  }
`;

export default HomeCardsStyled;
