import CONSTANTS from "config/constants";

const { hd, wide, desktop, tablet } = CONSTANTS.BREAKPOINTS;

export const responsive = {
  wide: {
    breakpoint: { max: hd, min: wide },
    items: 1
  },
  desktop: {
    breakpoint: { max: wide, min: desktop },
    items: 1
  },
  tablet: {
    breakpoint: { max: desktop, min: tablet },
    items: 1
  },
  mobile: {
    breakpoint: { max: tablet, min: 614 },
    items: 1
  },
  smaller: {
    breakpoint: { max: 614, min: 359 },
    items: 1
  },
  xs: {
    breakpoint: { max: 359, min: 0 },
    items: 1
  }
};
