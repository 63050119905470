/* istanbul ignore file */
// Home helper functions and data

export const homeSliderImageConfig = {
  width: 210,
  height: 210
};

export const homeSliderImageConfigMobile = {
  width: 168,
  height: 168
};
