import styled from "styled-components";

import { ProductGalleryStyledProps as Props } from "./ProductGallery.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop, tablet } = BREAKPOINTS;

const ProductGalleryStyled = styled.div<Props>`
  .ProductGallery {
    &__title {
      padding-bottom: 2rem;
      font-size: 2rem;
    }

    &__gallery {
      height: auto;
    }

    &__grid-item {
      @media (max-width: ${desktop - 1}px) {
        justify-items: center;
      }

      @media (max-width: ${tablet}px) {
        &:first-child {
          border-right: 0.4rem solid transparent;
          border-bottom: 0.4rem solid transparent;
        }

        &:nth-child(2) {
          border-bottom: 0.4rem solid transparent;
        }

        &:nth-child(3) {
          border-right: 0.4rem solid transparent;
        }

        &:nth-child(5) {
          border-right: 0.4rem solid transparent;
          border-top: 0.4rem solid transparent;
        }

        &:nth-child(6) {
          border-top: 0.4rem solid transparent;
        }
      }
    }

    &__productCard {
      display: flex;
      justify-content: center;
    }

    &__button {
      font-size: 1.2rem;
      font-weight: 600;

      svg {
        margin-left: 0.8rem;

        path {
          fill: var(--palette-primary);
        }
      }
    }
  }

  .ProductCardImage__image {
    width: initial;

    .Image__img {
      max-width: initial;
    }
  }
`;

export const ProductGalleryPlaceholderStyled = styled.div<Props>`
  width: 100%;
  display: flex;

  .ProductGalleryPlaceholder {
    &__items {
      width: 100%;

      .ProductGridPlaceholder {
        display: flex;

        &:last-child {
          margin-top: 3.2rem;
        }

        @media (max-width: ${tablet}px) {
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export default ProductGalleryStyled;
