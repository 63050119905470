import { useFetchBanners } from "@sushicorp/services";
import React, { useEffect, useRef, useState } from "react";
import CarouselUI from "react-multi-carousel";

import { responsive } from "./Carousel.helpers";
import Styles from "./Carousel.styles";
import { CarouselProps as Props } from "./Carousel.types";
import CarouselBanner from "./CarouselBanner/CarouselBanner";
import CategoriesMenu from "components/global/CategoriesMenu/CategoriesMenu";
import CONSTANTS from "config/constants";
import useIsomorphicLayoutEffect from "hooks/useIsomorphicLayoutEffect";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_CATEGORIES_MENU } = FEATURE_FLAGS;

const Carousel: React.FC<Props> = props => {
  const { className, bannerCategoryName } = props;
  const { data: banners } = useFetchBanners(bannerCategoryName);
  const bannersLength = banners?.length;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (!bannersLength)
      console.warn(
        "Warning: Unable to display. There are no images to display on the carousel "
      );
  }, [bannersLength]);

  const carouselHandler = (value: number) => {
    setCounter(value > bannersLength! ? value - bannersLength! : value);
  };

  useIsomorphicLayoutEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
  }, []);

  return (
    <Styles
      withCategoryMenu={WITH_CATEGORIES_MENU}
      ref={wrapperRef}
      className={`Carousel ${className}`}
    >
      {WITH_CATEGORIES_MENU ? (
        <CategoriesMenu className="Carousel__categories" />
      ) : null}
      {bannersLength ? (
        <>
          <CarouselUI
            swipeable
            draggable
            additionalTransfrom={0}
            autoPlaySpeed={3000}
            showDots={false}
            arrows
            responsive={responsive}
            infinite
            keyBoardControl
            containerClass="Carousel__container"
            partialVisible={false}
            autoPlay
            afterChange={carouselHandler}
          >
            {banners?.map((banner, index) => {
              return <CarouselBanner key={index} banner={banner} />;
            })}
          </CarouselUI>
          <div className="Carousel__counter">
            {counter}/{bannersLength}
          </div>
        </>
      ) : null}
    </Styles>
  );
};

Carousel.defaultProps = {
  className: ""
};

export default Carousel;
