import React from "react";

import { CategorySliderPlaceholderStyled as Styles } from "./CategorySlider.styles";
import { CategorySliderPlaceholderProps as Props } from "./CategorySlider.types";

const CategorySliderPlaceholder: React.FC<Props> = () => {
  return (
    <Styles className="CategorySliderPlaceholder">
      <div className="CategorySliderPlaceholder__title loading-shine" />
      <div className="CategorySliderPlaceholder__items">
        <div className="CategorySliderPlaceholder__item loading-shine" />
        <div className="CategorySliderPlaceholder__item loading-shine" />
        <div className="CategorySliderPlaceholder__item loading-shine" />
      </div>
    </Styles>
  );
};

export default CategorySliderPlaceholder;
