import styled from "styled-components";

import { CategorySliderStyledProps as Props } from "./CategorySlider.types";
import CONSTANTS from "../../../config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const CategorySliderStyled = styled.div<Props>`
  .CategorySlider {
    &__title {
      font-size: 2.4rem;
      color: var(--palette-black);
      font-weight: 700;
      padding-bottom: 1.2rem;

      @media (max-width: ${tablet}px) {
        font-size: 2rem;
      }
    }

    .slider-container {
      overflow-x: hidden;
    }
  }
`;

export const CategorySliderPlaceholderStyled = styled.div<Props>`
  @media (min-width: ${tablet}px) {
    width: 32rem;
  }

  .CategorySliderPlaceholder {
    &__title {
      height: 2.4rem;
      width: 36rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.8rem;
    }

    &__items {
      margin-top: 3.6rem;
      display: flex;
      width: 640rem;
    }

    &__item {
      height: 6rem;
      width: 24rem;
      border-radius: 0.8rem;
      background-color: var(--palette-gray-s0-l95);

      &:not(:last-child) {
        margin-right: 2.4rem;
      }

      @media (min-width: ${tablet}px) {
        width: 36rem;
        height: 24rem;
      }
    }
  }
`;

export default CategorySliderStyled;
