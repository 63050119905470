import React from "react";

import { ProductGalleryPlaceholderStyled as Styles } from "./ProductGallery.styles";
import { ProductGalleryPlaceholderProps as Props } from "./ProductGallery.types";
import ProductGridPlaceholder from "../ProductGridPlaceholder/ProductGridPlaceholder";

const ProductGalleryPlaceholder: React.FC<Props> = () => {
  return (
    <Styles className="ProductGalleryPlaceholder">
      <div className="ProductGalleryPlaceholder__items">
        <ProductGridPlaceholder quantity={4} />
        <ProductGridPlaceholder quantity={4} />
      </div>
    </Styles>
  );
};

export default ProductGalleryPlaceholder;
