import { useRouter } from "next/router";
import React from "react";

import Styles from "./HomeCards.styles";
import { HomeCardsProps as Props } from "./HomeCards.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const { APP_URL } = CONSTANTS.INTEGRATIONS;

const HomeCards: React.FC<Props> = () => {
  const t = useI18n();
  const { isAnonymous } = useAuth();
  const { push } = useRouter();

  /**
   * Function to handle the click on the coupons button.
   *
   * @since 1.0.0
   */
  const onClickCoupons = () => {
    if (isAnonymous) {
      push("/signin");
      return;
    }

    push("/profile/coupons");
  };

  const onClickDownload = () => push(APP_URL);

  return (
    <Styles className="HomeCards">
      <div className="HomeCards__card">
        <div className="HomeCards__content">
          {/* TODO: uncomment if is necessary */}
          {/* <div>
            <h3 className="HomeCards__title">{t.home.card.download}</h3>
            <h3 className="HomeCards__title HomeCards__title--primary">
              {t.home.card.ourApp}
            </h3>
          </div> */}

          <Button
            className="HomeCards__download-app-button"
            onClick={onClickDownload}
          >
            {t.home.card.downloadNow}
          </Button>
        </div>
      </div>
      <div className="HomeCards__card">
        <div className="HomeCards__content">
          {/* TODO: uncomment if is necessary */}
          {/* <div>
            <h3 className="HomeCards__title">{t.home.card.useYour}</h3>
            <h3 className="HomeCards__title HomeCards__title--secondary">
              {t.home.card.couponsOf}
            </h3>
            <h3 className="HomeCards__title HomeCards__title--secondary">
              {t.home.card.discount}
            </h3>
          </div> */}
          <Button
            className="HomeCards__download-app-button HomeCards__download-app-button--secondary"
            onClick={onClickCoupons}
          >
            {t.home.card.coupons}
          </Button>
        </div>
      </div>
    </Styles>
  );
};

HomeCards.defaultProps = {};

export default HomeCards;
