/* istanbul ignore file */
import styled from "styled-components";

import { HomeStyledProps as Props } from "./Home.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile, tablet, desktop, wide } = BREAKPOINTS;

const HomeStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  background-color: var(--palette-gray-s0-l97);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  overflow-x: hidden;

  .Home {
    &__title {
      display: none;
    }

    &__header {
      grid-column: fullBleed;
    }

    &__main {
      flex: 1;
      display: flex;
      flex-direction: column;
      grid-column: content-start / content-end;
    }

    &__greetings {
      &__container {
        display: flex;
        align-items: center;
        margin-top: 6.4rem;
      }

      &__text {
        font-weight: 700;
        font-size: 2.6rem;
        color: var(--palette-black-s0-l10);
        margin-right: 0.4rem;

        @media (max-width: ${tablet}px) {
          font-size: 2rem;
        }
      }
    }

    &__carousel {
      position: relative;
      grid-column: fullBleed;
      padding-top: calc(var(--sizes-navbar-height) - env(safe-area-inset-top));

      & .Image__img {
        object-fit: cover;
      }

      @media (max-width: ${desktop}px) {
        padding-top: ${props =>
          !props.withBottomResponsiveButtons
            ? "var(--sizes-navbar-height-mobile-without-bottom-buttons)"
            : props.withBottomSearch
            ? "var(--sizes-navbar-height-mobile)"
            : "var(--sizes-navbar-height-mobile-without-search)"};
      }

      .Controls__arrow {
        background-color: transparent;
      }
    }

    &__category-slider {
      margin-top: 8rem;
      position: relative;
      overflow: hidden;
      margin-bottom: 4rem;
      max-width: ${wide}px;
      margin-inline: auto;

      @media (max-width: ${tablet}px) {
        margin-top: 4.4rem;
        margin-bottom: 0rem;
      }

      .Slider {
        overflow: visible;
        margin-top: 2rem;

        &__slide {
          height: 100%;
        }

        svg {
          &.slick-prev,
          &.slick-next {
            top: -4.6rem;
            left: ${props => (props.isEnglish ? "34rem" : "28rem")};
            width: auto;
            z-index: 1;
            height: unset;

            @media (max-width: ${tablet}px) {
              display: none !important;
            }
          }

          &.slick-next {
            left: ${props => (props.isEnglish ? "38rem" : "32rem")};
          }
        }
      }
    }

    &__category-gallery {
      padding-top: 20rem;

      @media (max-width: ${tablet}px) {
        padding-top: 8rem;
      }

      @media (max-width: ${tablet}px) {
        padding-top: 5.6rem;
      }

      .Image__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__products-slider {
      display: block;
      padding-top: 16rem;
      padding-bottom: 3.2rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__product-gallery {
      padding: 4rem 0;
      position: relative;

      @media (max-width: ${tablet}px) {
        margin-bottom: 24rem;
      }

      & .Gallery {
        & .Gallery__grid {
          display: grid;
        }

        & .Gallery__grid__item {
          display: flex;
          justify-content: center;

          @media (max-width: ${mobile}px) {
            width: 100%;
          }
        }

        &--3 {
          & .Gallery__grid {
            grid-template-columns: repeat(3, 1fr);

            @media (max-width: ${desktop}px) {
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: auto;
            }

            @media (max-width: ${tablet}px) {
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: auto;
            }
          }
        }

        &--4 {
          & .Gallery__grid {
            grid-template-columns: repeat(2, 1fr);

            @media (min-width: ${mobile + 1}px) {
              grid-template-columns: repeat(2, 1fr);
            }

            @media (min-width: ${tablet + 1}px) {
              grid-template-columns: repeat(4, 1fr);
            }
          }
        }

        &--5 {
          & .Gallery__grid {
            grid-template-columns: repeat(5, 1fr);

            @media (max-width: ${desktop}px) {
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: auto;
            }

            @media (max-width: ${tablet}px) {
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: auto;
            }
          }
        }

        &--6 {
          & .Gallery__grid {
            grid-template-columns: repeat(6, 1fr);

            @media (max-width: ${desktop}px) {
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: auto;
            }

            @media (max-width: ${tablet}px) {
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: auto;
            }
          }
        }
      }

      .ProductGallery {
        &__productCard > *,
        &__productCard {
          width: 16rem;
          height: 30rem;

          @media ((min-width: ${mobile + 100}px) and (max-width: ${tablet}px)) {
            width: 22rem;
          }

          @media ((min-width: ${desktop}px) and (max-width: ${wide - 1}px)) {
            width: 90%;
          }

          @media (min-width: ${wide}px) {
            width: 30.8rem;
            height: 43.8rem;
          }
        }

        &__gallery {
          background-color: transparent;
          position: relative;
          padding: 0 1.6rem;
          padding-bottom: 9.2rem;
          top: 11.4rem;
          height: 50rem !important;

          @media (max-width: ${tablet}px) {
            padding: 0;
            padding-bottom: 9.2rem;
          }

          & .Gallery {
            &__grid {
              gap: 1.6rem;
            }

            @media (max-width: ${desktop}px) {
              &__grid {
                gap: 1.6rem;
              }
            }

            @media (max-width: ${tablet}px) {
              &__grid {
                column-gap: 0;
                row-gap: 1.6rem;
              }
            }
          }

          & .ProductCard {
            &__card {
              background-color: var(--palette-white);
              border-radius: 0.8rem;
              padding: 1.2rem;
            }

            &__image {
              width: 13.7rem;
              height: 13.5rem;
              margin-inline: auto;

              @media (min-width: ${wide}px) {
                width: 28rem;
                height: 26rem;
              }
            }
          }

          & .ProductCardImage__image {
            width: 13.7rem;
            height: 13.5rem;

            @media (min-width: ${wide}px) {
              width: 26rem;
              height: 26rem;
            }
          }

          & .ProductCardImage__image > * {
            width: 13.7rem;
            height: 13.5rem;

            @media (min-width: ${wide}px) {
              width: 27.8rem;
              height: 26rem;
            }
          }
        }

        &__aside {
          background-image: url("/assets/images/category-kobe-background.png");
          background-position: right;
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          background-color: var(--palette-primary);
          height: 24rem;
          border-radius: 4rem;
          position: absolute;
          display: flex;
          justify-content: flex-start;

          @media (max-width: ${tablet}px) {
            background-position: top right;
            background-size: 28rem;
            border-top-left-radius: 0rem;
            border-bottom-left-radius: 0rem;
            width: 80%;
            height: 25.8rem;
          }

          @media (max-width: ${mobile}px) {
            background-position: top right;
            width: 90%;
            background-size: 16rem;
          }
        }

        &__title {
          padding: 4rem;
          padding-right: 1.6rem;
          font-size: 4.4rem;
          color: var(--palette-gray-s0-l97);
          font-weight: 700;
          text-align: left;

          @media (max-width: ${tablet}px) {
            font-size: 3.2rem;
            text-align: left;
            padding-left: 2rem;
            padding-right: 0.4rem;
          }

          @media (max-width: ${mobile}px) {
            font-size: 2.4rem;
          }
        }

        &__button {
          color: var(--palette-gray-s0-l97);
          display: flex;
          align-items: flex-start;
          padding-top: 6.4rem;
          font-weight: 700;
          font-size: 1.6rem;

          &:hover {
            color: var(--palette-white);
          }

          @media (max-width: ${tablet}px) {
            padding-top: 5.6rem;
          }

          @media (max-width: ${mobile}px) {
            padding-top: 5.2rem;
          }
        }
      }
    }

    &__footer {
      grid-column: 1 / -1;
      padding-top: 3.2rem;
    }
  }
`;

export default HomeStyled;
