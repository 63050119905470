import styled from "styled-components";

import { HomeFABStyledProps as Props } from "./HomeFAB.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const HomeFABStyled = styled.div<Props>`
  position: fixed;
  right: 1.6rem;
  bottom: 1.6rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  @media (max-width: ${tablet}px) {
    right: 0.8rem;
    bottom: 8rem;
  }

  .HomeFAB {
    &__whatsapp {
      background-color: var(--palette-white);
      border-radius: 50%;
      padding: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--palette-gray-s0-l90);

      &__icon {
        transform: scaleX(-1.75) scaleY(1.75);
      }
    }
  }
`;

export default HomeFABStyled;
