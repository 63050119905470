import { events } from "artisn/analytics";

import Styles from "./HomeFAB.styles";
import { HomeFABProps as Props } from "./HomeFAB.types";
import CONSTANTS from "config/constants";

import WhatsappSVG from "../../../../../public/assets/images/whatsapp.svg";

const { INTEGRATIONS } = CONSTANTS;
const { WHATSAPP } = INTEGRATIONS;
const { FAB_NUMBER } = WHATSAPP;
const { logCustomEvent } = events;

const HomeFAB: React.FC<Props> = props => {
  return (
    <Styles className="HomeFAB">
      <a
        className="HomeFAB__whatsapp"
        href={`https://api.whatsapp.com/send?phone=${FAB_NUMBER}`}
        target="_blank"
        rel="noreferrer"
        onClick={() => logCustomEvent("ws_button_click")}
      >
        <WhatsappSVG className="HomeFAB__whatsapp__icon" />
      </a>
    </Styles>
  );
};

export default HomeFAB;
