import { useProducts } from "@sushicorp/contexts";
import { Image } from "artisn-ui-react";
import React from "react";

import { goToBannerUrlHelper } from "./CarouselBanner.helper";
import Styles from "./CarouselBanner.styles";
import { CarouselBannerProps as Props } from "./CarouselBanner.types";
import Button from "components/global/Button/Button";
import useI18n from "hooks/useI18n";

import FireSVG from "../../../../../public/assets/images/fire.svg";

const CarouselBanner: React.FC<Props> = props => {
  const { className, banner } = props;
  const { setOpenProductModal } = useProducts();
  const t = useI18n();

  const { image_url: imageUrl, banner_description: bannerDescription } = banner;
  const { banner_title: bannerTitle } = banner;

  /**
   * Executed in click event of banner.
   *
   * @since 1.0.0
   */
  const clickHandler = () => {
    goToBannerUrlHelper(banner, setOpenProductModal);
  };

  return (
    <Styles className={`CarouselBanner ${className}`}>
      <Image
        className="CarouselBanner__images"
        image={imageUrl}
        alt={bannerDescription}
        onClick={clickHandler}
      />
      <div className="CarouselBanner__body">
        <p className="CarouselBanner__title">{bannerTitle}</p>

        <p className="CarouselBanner__description">{bannerDescription}</p>

        <div className="CarouselBanner__buttons Button--no-hover">
          <Button
            className="CarouselBanner__buttons__order"
            onClick={clickHandler}
          >
            <FireSVG />

            {t.common.orderNow}
          </Button>
        </div>
      </div>
    </Styles>
  );
};

CarouselBanner.defaultProps = {};

export default CarouselBanner;
