import styled from "styled-components";

import { CarouselBannerStyledProps as Props } from "./CarouselBanner.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;

const { tablet, mobile } = BREAKPOINTS;

const CarouselBannerStyled = styled.div<Props>`
  width: 100%;
  background-color: var(--palette-primary);

  .CarouselBanner {
    &__images {
      border-radius: 0;

      .Image {
        &__img {
          width: 100%;
          min-width: auto;
          height: auto;

          @media (max-width: ${tablet - 1}px) {
            min-width: auto;
            height: auto;
          }

          @media (max-width: ${mobile - 1}px) {
            min-width: auto;
            max-width: max-content;
            height: 36rem;
          }
        }
      }

      &::after {
        content: "";

        background-image: linear-gradient(
          180deg,
          transparent,
          var(--palette-black-s15-l8)
        );

        height: 24rem;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    &__body {
      height: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      bottom: 8.8rem;

      @media (min-width: ${tablet + 1}px) {
        align-items: flex-start;
        padding-inline: 8rem;
      }
    }

    &__footer {
      height: 4.8rem;
      background-color: var(--palette-primary);

      @media (min-width: ${tablet}px) {
        height: 12rem;
      }
    }

    &__title {
      padding-left: 1.6rem;
      color: var(--palette-white);
      font-size: 4rem;
      font-weight: 700;
      max-width: 44rem;
      text-transform: uppercase;

      @media (max-width: ${tablet - 1}px) {
        max-width: 36rem;
        font-size: 2.4rem;
      }
    }

    &__description {
      color: var(--palette-white);
      padding-left: 1.6rem;
      font-size: 1.8rem;
      font-weight: 500;
      padding-top: 2.4rem;
      max-width: 40rem;

      @media (max-width: ${tablet - 1}px) {
        font-size: 1.6rem;
        max-width: 32rem;
        padding-top: 1.6rem;
      }
    }

    &__buttons {
      display: flex;

      &__order {
        font-weight: 700;
        font-size: 1.6rem;
        color: var(--palette-white);
        background-color: var(--palette-primary);
        border: none;
        border-radius: 3.2rem;
        height: 5.6rem;

        @media (max-width: ${tablet - 1}px) {
          font-size: 1.4rem;
        }
      }

      & .Button:hover:not([disabled]) {
        box-shadow: none;
        border: none;
        background-color: var(--palette-black);
      }
    }
  }
`;

export default CarouselBannerStyled;
