import { Storage } from "@capacitor/storage";
import { goToCategory } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import React from "react";

import Styles from "./ProductGallery.styles";
import { ProductGalleryProps as Props } from "./ProductGallery.types";
import Button from "../Button/Button";
import Gallery from "../Gallery/Gallery";
import ProductCard from "../ProductCard/ProductCard";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import { notify } from "utils/common.utils";

const { BREAKPOINTS, STORAGE } = CONSTANTS;
const { CATEGORY_TOKEN } = STORAGE;
const { mobile, tablet, desktop } = BREAKPOINTS;
const { logSelectCategory } = events.category;

const ProductGallery: React.FC<Props> = props => {
  const { category, className, title } = props;
  const { width } = useWindowSize();
  const isMobile = width < mobile;
  const isDesktop = width < desktop + 350 && width >= tablet;
  const { name, products, categoryId } = category ?? {};

  const productWidth = isMobile ? 140 : isDesktop ? 160 : 220;
  const productHeight = isMobile ? 240 : isDesktop ? 300 : 352;

  const goToCategoryDetail = async () => {
    try {
      await Storage.set({
        key: CATEGORY_TOKEN,
        value: JSON.stringify(categoryId)
      });
    } catch (e) {
      notify(e, "Go to category detail");
      console.error("Cannot save category in storage");
    }
    logSelectCategory({
      category,
      list: name
    });
    goToCategory(category);
  };

  return (
    <Styles className={`ProductGallery ${className}`}>
      <div className="ProductGallery__aside">
        <h2 className="ProductGallery__title">{title ?? name}</h2>
        <Button
          className="ProductGallery__button"
          type="LINK"
          onClick={goToCategoryDetail}
        >
          Ver todos
        </Button>
      </div>
      <Gallery
        maxItems={6}
        fixedLayout={true}
        className="ProductGallery__gallery"
      >
        {products.map(product => {
          const { productId } = product;
          return (
            <div className="ProductGallery__grid-item" key={productId}>
              <ProductCard
                width={productWidth}
                height={productHeight}
                product={product}
                className="ProductGallery__productCard"
              />
            </div>
          );
        })}
      </Gallery>
    </Styles>
  );
};

ProductGallery.defaultProps = {
  className: ""
};

export default ProductGallery;
